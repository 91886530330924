import './Birthday.css';
import 'bootstrap/dist/css/bootstrap.min.css';


import BirthdayCounter from './components/BirthdayCounter.js';

function App() {
  return (
      <>
        <BirthdayCounter />
      </>
  );
}

export default App;

import React from 'react';
import Moment from 'react-moment';
import moment from 'moment';

import {Jumbotron,Container,Row,Col,Card} from 'react-bootstrap';

import Confetti from 'react-dom-confetti';

import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Roll from 'react-reveal/Roll';
import Rotate from 'react-reveal/Rotate';
import Bounce from 'react-reveal/Bounce';



class BirthdayCounter extends React.Component {
    
    constructor (props) {
        super(props);
        
        this.state = {
            duration : null,
            seconds  : null,
            show     : true,
            
            confetti_position: null,
            image: null
        }
    }
    
    theMoment = moment('04-16-2022 07:21', "MM-DD-YYYY HH:mm");
    
    //theMoment = moment().add(6, 'seconds');
    
    positions  = ['cc','lt','rb','rt','lb'];
    
    confetti_positions = Array.from(this.positions);
    
    
    imageTimer = 1;
    
    imageSize=320 
    
    
    images = [
      <Zoom distance="80%">
        <img height={this.imageSize} width={this.imageSize} src="/img/birthday/1.jpg" /></Zoom>,
      <Zoom distance="80%" top>
        <img height={this.imageSize} width={this.imageSize} src="/img/birthday/2.jpg" /></Zoom>,
      <Zoom distance="80%" right>
        <img height={this.imageSize} width={this.imageSize} src="/img/birthday/3.jpg" /></Zoom>,
      
      <Fade distance="80%">
        <img height={this.imageSize} width={this.imageSize} src="/img/birthday/4.jpg" /></Fade>,
      <Fade distance="80%" bottom>
        <img height={this.imageSize} width={this.imageSize} src="/img/birthday/5.jpg" /></Fade>,
      <Fade distance="80%" left>
        <img height={this.imageSize} width={this.imageSize} src="/img/birthday/6.jpg" /></Fade>,
      
      <Roll distance="80%">
        <img height={this.imageSize} width={this.imageSize} src="/img/birthday/7.jpg" /></Roll>,
      <Roll distance="80%" top>
        <img height={this.imageSize} width={this.imageSize} src="/img/birthday/8.jpg" /></Roll>,
      <Roll distance="80%" right>
        <img height={this.imageSize} width={this.imageSize} src="/img/birthday/9.jpg" /></Roll>,
      
      <Rotate distance="80%">
        <img height={this.imageSize} width={this.imageSize} src="/img/birthday/10.jpg" /></Rotate>,
      <Rotate distance="80%" bottom>
        <img height={this.imageSize} width={this.imageSize} src="/img/birthday/11.jpg" /></Rotate>,
      <Rotate distance="80%" left>
        <img height={this.imageSize} width={this.imageSize} src="/img/birthday/12.jpg" /></Rotate>,
      
      <Bounce distance="80%" bottom>
        <img height={this.imageSize} width={this.imageSize} src="/img/birthday/13.jpg" /></Bounce>,
      <Bounce distance="80%" left>
        <img height={this.imageSize} width={this.imageSize} src="/img/birthday/14.jpg" /></Bounce>,
      <Bounce distance="80%" right>
        <img height={this.imageSize} width={this.imageSize} src="/img/birthday/15.jpg" /></Bounce>,
      <Bounce distance="80%" top>
        <img height={this.imageSize} width={this.imageSize} src="/img/birthday/16.jpg" /></Bounce>,
      <Bounce distance="80%" bottom>
        <img height={this.imageSize} width={this.imageSize} src="/img/birthday/17.jpg" /></Bounce>
    ];
    
    randomize = (arr) =>  {
      var i, j, tmp;
      for (i = arr.length - 1; i > 0; i--) {
          j = Math.floor(Math.random() * (i + 1));
          tmp = arr[i];
          arr[i] = arr[j];
          arr[j] = tmp;
      }
      return arr;
    }
    
    images = this.randomize(this.images);
    
    
    updateCountdown = () => {
        
        var seconds = this.theMoment.diff(moment(), 'seconds');
        
        if(seconds > 0)
        {
            this.setState({
                duration : moment.duration(seconds, 'seconds'),
                seconds  : seconds,
                show     : true,
                
                confetti_position: null,
                image: null
            })
            
            this.tickTimer();
        }
        else
        {
            if(seconds > - 300 )
            {
                this.popConfetti();
                
                 this.tickTimer();
            }
            else
            {
                this.setState({
                    duration : null,
                    seconds  : null,
                    show     : false,
                    
                    confetti_position: null,
                    image: null
                })
            }
        }
        
    }
    
    popConfetti  = () => {
      
        var pos = this.positions.shift();
        this.positions.push(pos);
        
        var image = this.state.image;
        
        if(this.imageTimer === 0)
        {
          var newImage = this.images.shift();
          
          this.images.push(newImage);
          
          image = newImage;
        }
        
        if(this.imageTimer === 5)
        {
          this.imageTimer = 0;
          image = null
        }
        else
        {
          this.imageTimer++;
        }
            
        this.setState({
            duration : null,
            seconds  : null,
            show     : false,
            
            confetti_position: pos,
            image: image
        })
    } 
    
    tickTimer = () => {
        
        window.setTimeout(this.updateCountdown, 1000);
    }
    
    handleClick = (e) =>
    {
      this.popConfetti()
    }
    
    componentDidMount()
    {
      Array(17).fill('').forEach((elem, num) => {
        
        const img = new Image();
        
        img.src = '/img/birthday/'+ (num + 1) +'.jpg';
    });
    
        this.updateCountdown();
    }
    
    render () {
        
        const config = {
          angle: "161",
          spread: 360,
          startVelocity: "50",
          elementCount: "350",
          dragFriction: "0.15",
          duration: "8490",
          stagger: "2",
          width: "10px",
          height: "10px",
          perspective: "442px",
          colors: ["#db275a", "#a80000", "#0f0", "#00f", '#32a852']
        };
        
        var d = this.state.duration;
        
        var days    = d ? d.days()    : 0;
        var hours   = d ? d.hours()   : 0;
        var minutes = d ? d.minutes() : 0;
        var seconds = d ? d.seconds() : 0;
        
        return (
            <>
            
            {this.confetti_positions.map((pos,key) => {
                
                return (
                    <div className={"confetti-container confetti-container-" + pos} key={key}>
                        <Confetti
                            active={ pos === this.state.confetti_position }
                            config={ config }
                        />
                    </div>
                )
            })}
            
            {/*<button type="button" className="btn btn-sm btn-outline-dark" onClick={this.handleClick}>Trigger confetti</button>*/}
            
            <div className="main-jumbotron main-jumbotron-images text-center">
                
              {this.state.image != null && this.state.image}

            </div>
            
            <Jumbotron className="main-jumbotron text-center m-5">
                <Container fluid={true}>
                
                    {(this.state.show) &&
                      <Zoom>
                        <h1 className="display-4">{'Jenny\'s 17th Birthday'}</h1>
                      </Zoom>
                    }
                    
                    {(!this.state.show) &&
                      <Zoom>
                        <h1 className="display-4">Happy Birthday!!!</h1>
                      </Zoom>
                    }
                    
                    
                    {(this.state.show) && <Row className={"my-5"}>
                        
                        <Col className="col-timer" sm={{ span: 2, offset: 2 }} xs={3}>
                            <Card>
                                <h4 className="my-2">{days}</h4>
                                <p>Days</p>
                            </Card>
                        </Col>
                        
                        <Col className="col-timer" sm={2}  xs={3}>
                            <Card>
                                <h4 className="my-2">{hours}</h4>
                                <p>Hours</p>
                            </Card>
                        </Col>
                        
                        <Col className="col-timer" sm={2}  xs={3}>
                            <Card>
                                <h4 className="my-2">{minutes}</h4>
                                <p>Minutes</p>
                            </Card>
                        </Col>
                        <Col className="col-timer" sm={2}  xs={3}>
                            <Card>
                                <h4 className="my-2">{seconds}</h4>
                                <p>Seconds</p>
                            </Card>
                        </Col>
                    </Row>}
                    <p className="lead m-0">{this.theMoment.format('MMMM Do, YYYY HH:mma')}</p>
                </Container>
            </Jumbotron>
            </>
        );
    };

}

export default BirthdayCounter;

